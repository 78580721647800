import { useState }              from 'react'
import { useNavigate }           from 'react-router-dom'
import { useAtom }               from 'state/jotai'
import { toggle_drawer }         from 'state/actions'
import { vis_mobile_nav }        from 'state/store.global'
import { favorites }             from 'state/store.global'
import { vis_compare }           from 'state/store.global'
import { compares }              from 'state/store.global'
import { useFetch_Colors }       from 'hooks/fetch/useFetch_Colors'
import { useFetch_Navigation }   from 'hooks/fetch/useFetch_Navigation'
import { Icon }                  from 'assets/Icons_'
import { Logo }                  from './Nav.Logo'
import { Foldout }               from './Nav.Mobile.Foldout'
import { useTranslation }        from 'react-i18next'
// import LanguageMenu              from 'components/LanguageMenu/LanguageMenu'

import { Menu, Gap } from './Nav.Mobile.style'


import NavItems from './NavItems'

export const NavMobile = () => {
  
  const { clr } = useFetch_Colors()
  const { nav } = useFetch_Navigation()
  const [ show, setShow ] = useAtom(vis_mobile_nav) 
  
  const { i18n:{language} } = useTranslation()

  const [state, setState] = useState()

  const navigateTo   = useNavigate()
  const toggleDrawer = useAtom(toggle_drawer)[1]
  const comptoggle   = useAtom(vis_compare)[1]
  const clickHelp    = () => { navigateTo(`${language}/help`); toggleDrawer() }
  const clickFavs    = () => { navigateTo(`${language}/favorites`); toggleDrawer() }
  const clickComp    = () => { comptoggle(a => !a) }
  const click        = () => { setShow(show ? false : true) }

  const compCount  = useAtom(compares)[0].length
  const likeCount  = useAtom(favorites)[0].length

  const heart = { color:clr.icons, icon:'heart7'     , size:28, count:likeCount }
  const comp  = { color:clr.icons, icon:'compare3'   , size:28, count:compCount }
  const bars  = { color:clr.icons, icon:'three_bars' , size:33, click }
  const close = { color:clr.icons, icon:'close1'     , size:25, click }
  const globe = { color:clr.icons, icon:'globe'      , size:28, click:() => setState(!state)}
  const glarr = { color:clr.icons, icon:'globe_arrow', size:10, click:() => setState(!state), 
    wrap:{position:'absolute',bottom:-8,right:-10,transform:state?'rotate(180deg)':'rotate(0deg)'}
  }

  return <>
      <Foldout nav={nav} show={show} setShow={setShow}/>
      {/* <LanguageMenu state={state} setState={setState}/> */}
      <Menu clr={clr}>
        <span>
          <Logo device='mobile' link='https://spaettehaven.dk/'/>
        </span>

        <NavItems hideNav={show} />

        <span>

          { !show ? <>
            {/* <Icon {...comp}  click={clickComp} hide={compCount < 2}/>
            <Gap i='20'/>
            <Icon {...heart} click={clickFavs} hide={likeCount < 1}/>
            <Gap i='20'/>
            <Icon {...globe} children={<Icon {...glarr} />}/>
            <Gap i='18'/>
            <div style={{width:1,height:'100%',background:clr.icons+40}}/> */}
            <Gap i='11'/>
            <Icon {...bars}/>
            <Gap i='11'/>
          </> :
          <>
            {/* <Gap i='15'/>
            <Icon {...globe} children={<Icon {...glarr}/>}/>
            <Gap i='18'/>
            <div style={{width:1,height:'100%',background:clr.icons+40}}/> */}
            <Gap i='15'/>
            <Icon {...close}/>
            <Gap i='15'/>
          </>
          }
        </span>
      </Menu>
  </>
}