const palette = {
  primary: {
    main: "#EC8976",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#00242B",
    contrastText: "#FFFFFF",
  },
  text: {
    primary: "#000000",
  },
  success: {
    main: "#0E912C",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
};

export default palette;
